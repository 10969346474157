import { Flex, Button } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type ButtonsProps = {
  inProgress: boolean;
  hasFiles: boolean;
  disabled?: boolean;
  onUpload: () => void;
  onTerminate: () => void;
  onReset: () => void;
};

const Buttons = ({
  inProgress,
  hasFiles = false,
  disabled = false,
  onUpload,
  onTerminate,
  onReset,
}: ButtonsProps) => {
  return (
    <Flex justifyContent="space-between">
      <Button disabled={disabled} onClick={onUpload}>
        Upload
      </Button>

      {hasFiles && !inProgress && (
        <Button variant="outline" onClick={onReset}>
          <FontAwesomeIcon icon={faTimes} /> Reset
        </Button>
      )}

      {inProgress && (
        <Button variant="secondary" onClick={onTerminate}>
          Cancel
        </Button>
      )}
    </Flex>
  );
};

export default Buttons;
