import { Box } from "rebass";
import { version } from "../../../package.json";

const Footer = () => {
  return (
    <Box
      mx="auto"
      py={4}
      maxWidth={["100%", "540px", "720px", "960px", "1140px"]}
      sx={{ textAlign: "center", fontFamily: "Didact Gothic" }}
      color="primary"
      as="footer"
    >
      Kardiolytics &copy; {new Date().getFullYear()} {version}
    </Box>
  );
};

export { Footer };
