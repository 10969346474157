import { Flex, Text } from "rebass";
import { Label, Textarea } from "@rebass/forms";
import { useForm } from "react-hook-form";
import ReactTooltip from "react-tooltip";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";

import theme from "../../../styles/theme";
import { archiveDescriptionSchema } from "./schema";

type ArchiveDescriptionProps = {
  description: string;
  onChange: (v: string, hasError: boolean) => void;
};

const ArchiveDescription = ({
  description,
  onChange,
}: ArchiveDescriptionProps) => {
  const { register, errors } = useForm({
    mode: "onChange",
    resolver: yupResolver(archiveDescriptionSchema),
  });

  const handleChange = (e: any) => {
    onChange(e.target.value, !!errors?.desc);
  };

  return (
    <Flex mb={4} alignItems="top" flexWrap={["wrap", "wrap", "nowrap"]}>
      <Label
        width="320px"
        htmlFor="desc"
        sx={{ display: "flex", alignItems: "center" }}
      >
        Examination description
        <FontAwesomeIcon
          icon={faQuestionCircle}
          color={theme.colors.success}
          style={{ marginLeft: 4, marginRight: 4, cursor: "pointer" }}
          data-tip="Here you can write any meaningful description about the examination.<br/>If left empty we will generate a basic description for you."
          data-type="info"
          data-multiline={true}
        />
        :
      </Label>
      <Flex flexDirection="column" width={"100%"}>
        <Textarea
          id="desc"
          name="desc"
          defaultValue={description}
          rows={4}
          ref={register()}
          onChange={handleChange}
        />
        <Text variant="formError">{errors.desc?.message}</Text>
      </Flex>
      <ReactTooltip />
    </Flex>
  );
};

export default ArchiveDescription;
