import { Box, Button, Flex, Heading, Text } from "rebass";
import TimeAgo from "react-timeago";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileMedicalAlt } from "@fortawesome/free-solid-svg-icons";

import { DatasetDetails } from "../../../store/interfaces/DatasetDetails.interface";
import { Link, ProgressBar } from "../../Common";
import { Paragraph } from "../../Layout";
import { ScanItemStatus } from ".";
import theme from "../../../styles/theme";
import { PdfViewer } from "./PdfViewer";

type ScanItemProps = {
  scan: DatasetDetails;
};

const ScanItemDetails = ({ scan }: ScanItemProps) => {
  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems={["flex-start", "center"]}
        flexDirection={["column", "row"]}
        flexWrap="wrap"
        sx={{
          borderLeftColor: "primary",
          borderLeftWidth: 10,
          borderLeftStyle: "solid",
        }}
        mb={4}
        px={4}
      >
        <Box py={2} sx={{ textTransform: "capitalize" }}>
          <Heading as="h3" mb={2}>
            {decodeURIComponent(scan.description || "")}
          </Heading>

          <Flex alignItems="baseline" flexWrap="wrap" mb={[2, 0]}>
            <Text variant="caps" fontSize="2" width="180px">
              Scan uploaded:
            </Text>
            <Text>
              <TimeAgo date={scan.uploadTimestamp || new Date()} />
            </Text>
          </Flex>

          <Flex alignItems="baseline" flexWrap="wrap" mb={[2, 0]}>
            <Text variant="caps" fontSize="2" width="180px">
              Current status:
            </Text>
            <ScanItemStatus status={scan.processingStatus} />
          </Flex>
        </Box>
        <Box mb={[3, 0]}>
          <Link href={`/dashboard/archive`}>
            <Button variant="outline">Go back</Button>
          </Link>
        </Box>
      </Flex>

      <Paragraph mb={4}>
        <Heading as="h3" mb={2} color="primary">
          Computation progress
        </Heading>
        <ProgressBar progress={scan.progress} />
      </Paragraph>

      {scan.reportUrl && (
        <Paragraph mb={4}>
          <Heading as="h3" mb={2} color="primary">
            Report
          </Heading>

          <Text>
            <Box display="inline-block" mr={2}>
              <FontAwesomeIcon
                icon={faFileMedicalAlt}
                color={theme.colors.success}
                size="lg"
              />
            </Box>
            <Link href={scan.reportUrl} absolute newWindow>
              Download report
            </Link>
          </Text>

          <PdfViewer pdfFile={scan.reportUrl} />
        </Paragraph>
      )}
    </>
  );
};

export { ScanItemDetails };
