import { DatasetOverview } from "../../../store/interfaces/DatasetOverview.interface";
import { ScanItemOverview } from "./ItemOverview";

type ScanListProps = {
  scans: DatasetOverview[];
};

const ScanList = ({ scans }: ScanListProps) => {
  return (
    <>
      {scans.map((scan) => (
        <ScanItemOverview scan={scan} key={scan.uploadTimestamp} />
      ))}
    </>
  );
};

export { ScanList };
