import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./App";
import { HtmlHead, Loader } from "./components/Common";
import { Layout } from "./components/Layout";

const SecuredRoute = ({
  component: Component,
  public: Public,
  ...rest
}: any) => {
  const { userId, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <>
        <HtmlHead fullTitle="Kardiolytics" />
        <Layout>
          <Loader caption="Authorizing..." />
        </Layout>
      </>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        userId ? (
          <Component {...props} />
        ) : Public ? (
          <Public {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default SecuredRoute;
