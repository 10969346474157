import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "rebass";

import { RootState } from "../../store";
import { activateNotification } from "../../store/slices/notifications";

const Notifier = () => {
  const dispatch = useDispatch();
  const active = useSelector((state: RootState) => state.notifications.active);

  const handleActivation = () => {
    dispatch(activateNotification());
  };

  if (active) {
    return <></>;
  }

  return (
    <Box my={2} sx={{ textAlign: "center" }}>
      <Button variant="outline" onClick={handleActivation}>
        Notify me on processing status change
      </Button>
    </Box>
  );
};

export default Notifier;
