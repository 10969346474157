import React from "react";
import { Box } from "rebass";

type ParagraphProps = {
  children: React.ReactNode;
  mb?: number;
};

const Paragraph = ({ children, mb = 0 }: ParagraphProps) => {
  return (
    <Box
      sx={{
        borderLeftColor: "fading",
        borderLeftWidth: 10,
        borderLeftStyle: "solid",
      }}
      px={4}
      pb={4}
      mb={mb}
    >
      {children}
    </Box>
  );
};

export { Paragraph };
