import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";

import { LIMIT_PER_PAGE } from "../constants";

import {
  DatasetsListSortDirection,
  RequestDatasetDetailsQuery,
  RequestUserDatasetsListQuery,
} from "../../aws/API";
import {
  requestDatasetDetails,
  requestUserDatasetsList,
} from "../../aws/graphql/queries";
import { DatasetOverview } from "../interfaces/DatasetOverview.interface";
import { DatasetDetails } from "../interfaces/DatasetDetails.interface";

export interface DatasetsInterface {
  queryStatus: string;
  lastScan: DatasetOverview | null;
  scan: DatasetDetails | null;
  scans: DatasetOverview[];
  nextToken?: string | null;
  error: any | null;
}

type PayloadSetLastScan = {
  lastScan: DatasetOverview;
};

const initialState: DatasetsInterface = {
  queryStatus: "idle",
  lastScan: null,
  scan: null,
  scans: [],
  nextToken: null,
  error: null,
};

interface QueryScans {
  userId: string;
  limit?: number;
  orderBy?: DatasetsListSortDirection;
  nextToken?: string | null;
}

interface QueryScan {
  userId: string;
  uploadTimestamp: string;
}

const queryScans = createAsyncThunk(
  "datasets/queryScans",
  async ({ userId, limit, orderBy, nextToken }: QueryScans) => {
    const { data } = (await API.graphql(
      graphqlOperation(requestUserDatasetsList, {
        user_id: userId,
        limit: limit || LIMIT_PER_PAGE,
        order_by: orderBy || DatasetsListSortDirection.UPLOAD_TIMESTAMP_DESC,
        next_token: nextToken || null,
      })
    )) as GraphQLResult<RequestUserDatasetsListQuery>;

    const result = data?.requestUserDatasetsList;

    if (!result) {
      return { scans: [], nextToken: null };
    }

    const scans: DatasetOverview[] = result.datasets_overviews.map(
      ({ user_id, description, upload_timestamp, webui_processing_status }) => {
        return {
          description,
          uploadTimestamp: upload_timestamp,
          userId: user_id,
          processingStatus: webui_processing_status,
        };
      }
    );

    return {
      scans,
      nextToken: result.next_token,
    };
  }
);

const queryScan = createAsyncThunk(
  "datasets/queryScan",
  async ({ userId, uploadTimestamp }: QueryScan) => {
    const { data } = (await API.graphql(
      graphqlOperation(requestDatasetDetails, {
        user_id: userId,
        upload_timestamp: uploadTimestamp,
      })
    )) as GraphQLResult<RequestDatasetDetailsQuery>;

    const result = data?.requestDatasetDetails;

    if (!result) {
      return { scan: null };
    }

    const {
      progress_percent,
      description,
      report_url,
      upload_timestamp,
      user_id,
      webui_processing_status,
    } = result;

    return {
      scan: {
        userId: user_id,
        uploadTimestamp: upload_timestamp,
        description,
        progress: progress_percent,
        reportUrl: report_url,
        processingStatus: webui_processing_status,
      },
    };
  }
);

const queryLastScan = createAsyncThunk(
  "datasets/queryLastScan",
  async ({ userId }: QueryScans) => {
    const { data } = (await API.graphql(
      graphqlOperation(requestUserDatasetsList, {
        user_id: userId,
        limit: 1,
        order_by: DatasetsListSortDirection.UPLOAD_TIMESTAMP_DESC,
      })
    )) as GraphQLResult<RequestUserDatasetsListQuery>;

    const result = data?.requestUserDatasetsList;

    if (!result) {
      return { lastScan: null };
    }

    const scans: DatasetOverview[] = result.datasets_overviews.map(
      ({ user_id, description, upload_timestamp, webui_processing_status }) => {
        return {
          description,
          uploadTimestamp: upload_timestamp,
          userId: user_id,
          processingStatus: webui_processing_status,
        };
      }
    );

    return { lastScan: scans[0] || null };
  }
);

const datasets = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    trimScans: (state) => {
      state.scans.splice(LIMIT_PER_PAGE);
      state.nextToken = null;
    },
    clearScans: () => {
      return initialState;
    },
    setLastScan: (state, { payload }: PayloadAction<PayloadSetLastScan>) => {
      state.lastScan = payload.lastScan;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(queryScans.pending, (state) => {
      state.queryStatus = "pending";
    });
    builder.addCase(queryScans.fulfilled, (state, { payload }) => {
      state.queryStatus = "idle";

      if (!state.nextToken) {
        state.scans = payload.scans;
      } else {
        state.scans.push(...payload.scans);
      }

      state.lastScan = state.scans[0] || null;
      state.nextToken = payload.nextToken;
    });
    builder.addCase(queryScans.rejected, (state) => {
      state.queryStatus = "idle";
    });

    builder.addCase(queryScan.pending, (state) => {
      state.queryStatus = "pending";
    });
    builder.addCase(queryScan.fulfilled, (state, { payload }) => {
      state.queryStatus = "idle";
      state.scan = payload.scan;
    });
    builder.addCase(queryScan.rejected, (state) => {
      state.queryStatus = "idle";
    });

    builder.addCase(queryLastScan.pending, (state) => {
      state.queryStatus = "pending";
    });
    builder.addCase(queryLastScan.fulfilled, (state, { payload }) => {
      state.queryStatus = "idle";
      state.lastScan = payload.lastScan;
    });
    builder.addCase(queryLastScan.rejected, (state) => {
      state.queryStatus = "idle";
    });
  },
});

export const datasetsReducer = datasets.reducer;

export const { trimScans, clearScans, setLastScan } = datasets.actions;

export { queryScans, queryScan, queryLastScan };
