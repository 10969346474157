import { useSelector } from "react-redux";

import { SignUp } from "../components/Auth/SignUp";
import { SignIn } from "../components/Auth/SignIn";
import { ForgotPassword } from "../components/Auth/ForgotPassword";
import { RootState } from "../store";
import { AuthState } from "../store/slices/auth";
import { Layout } from "../components/Layout/Layout";
import { useContext } from "react";
import { AuthContext } from "../App";
import { ResetPassword } from "../components/Auth/ResetPassword";
import { HtmlHead } from "../components/Common";
import { Redirect } from "react-router-dom";

function Login() {
  const authState = useSelector((state: RootState) => state.auth.authState);
  const { userId } = useContext(AuthContext);

  if (userId) {
    return <Redirect to="/dashboard/current" />;
  }

  return (
    <>
      <HtmlHead title="Login" />
      <Layout>
        {authState === AuthState.SignIn && <SignIn />}
        {authState === AuthState.SigningUp && <SignUp />}
        {authState === AuthState.ForgotPassword && <ForgotPassword />}
        {authState === AuthState.ResetPassword && <ResetPassword />}
      </Layout>
    </>
  );
}

export default Login;
