import { Auth } from "aws-amplify";
import { useContext, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Box, Flex, Image, Link as RebassLink } from "rebass";
import { AuthContext } from "../../App";

import { Link } from "../Common";
import { IconHamburger } from "./IconHamburger";

type NavBarProps = {
  logoSrc: string;
};

const navDesktop = {
  width: "66%",
  py: 2,
  textAlign: "right",
  display: ["none", "none", "none", "block"],
  a: {
    fontSize: 2,
    fontWeight: "normal",
    display: "inline-block",
    py: "0.65rem",
    px: ["26px", "26px", "26px", "35px", "50px"],
    color: "inherit",
    textDecoration: "none",
    letterSpacing: 1,
    backgroundColor: "fading",
    transition: "0.3s",
    "&.active": {
      color: "inversed",
      backgroundColor: "primary",
    },
    ":hover:not(.active)": {
      color: "primary",
      backgroundColor: "fadingDark",
    },
  },
};

const navMobile = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "opacity",
  opacity: 0.95,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
  a: {
    fontSize: 5,
    marginTop: 4,
    color: "primary",
    textDecoration: "none",
    "&.active": {
      textDecoration: "underline",
    },
  },
};

const hamburgerLink = {
  width: "33%",
  py: 2,
  pr: 4,
  textAlign: "right",
  zIndex: 1000,
  display: ["block", "block", "block", "none"],
};

const NavBar = ({ logoSrc }: NavBarProps) => {
  const match = useRouteMatch();

  const [isMobile, setMobile] = useState(false);
  const { user } = useContext(AuthContext);

  const toggleMobile = () => setMobile(!isMobile);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        as="header"
        sx={{ fontFamily: "Didact Gothic" }}
        py={4}
      >
        <Box width={[2 / 3, 1 / 2, 1 / 3, 1 / 3, 1 / 3]} pl={4}>
          <Link href="/" ariaLabel="Homepage">
            <Image
              src={logoSrc}
              maxWidth={320}
              sx={{ position: "relative", top: "4px" }}
              alt="kardiolytics logo"
            ></Image>
          </Link>
        </Box>
        <Box sx={isMobile ? navMobile : navDesktop} as="nav">
          <Link href="/" active={match.path === "/"}>
            Home
          </Link>
          <Link href="/dashboard" active={match.path.startsWith("/dashboard")}>
            Dashboard
          </Link>
          <Link href="https://kardiolytics.com" absolute>
            Go to Kardiolytics.com
          </Link>
          {user ? (
            <RebassLink variant="link" onClick={() => Auth.signOut()}>
              Logout
            </RebassLink>
          ) : (
            <Link href="/login" active={match.path === "/login"}>
              Log In
            </Link>
          )}
        </Box>
        <Box sx={hamburgerLink} as="nav">
          <IconHamburger active={isMobile} onHamburgerClick={toggleMobile} />
        </Box>
      </Flex>
    </>
  );
};

export { NavBar };
