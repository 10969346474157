import { Box } from "rebass";

type PropsType = {
  active: boolean;
  onHamburgerClick: () => void;
};

const iconStyles = {
  display: "inline-block",
  cursor: "pointer",
};

const barStyles = {
  width: "33px",
  height: "3px",
  margin: "8px 0",
  transition: "0.3s",
  borderRadius: "20%",
};

const IconHamburger = ({ active = false, onHamburgerClick }: PropsType) => {
  const stateClass = active
    ? { backgroundColor: "primary" }
    : { backgroundColor: "primary" };

  return (
    <Box sx={{ ...iconStyles }} onClick={onHamburgerClick}>
      <Box
        className="bar"
        sx={{ ...barStyles, ...stateClass }}
        style={
          active
            ? {
                transform: "rotate(-45deg) translate(-8px, 8px)",
                backgroundColor: "primary",
              }
            : {}
        }
      />
      <Box
        sx={{ ...barStyles, ...stateClass }}
        style={active ? { opacity: 0 } : {}}
      />
      <Box
        sx={{ ...barStyles, ...stateClass }}
        style={
          active ? { transform: "rotate(45deg) translate(-8px, -8px)" } : {}
        }
      />
    </Box>
  );
};

export { IconHamburger };
