import React from "react";
import { Box } from "rebass";
import { Footer } from "./Footer";

import { NavBar } from "./NavBar";

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <NavBar logoSrc="/logo.svg" />

      <Box
        variant="container"
        as="section"
        sx={{ position: "relative", fontFamily: "Didact Gothic" }}
      >
        {children}
      </Box>

      <Footer />
    </>
  );
};

export { Layout };
