import { Box, Button, Flex, Heading, Text } from "rebass";
import TimeAgo from "react-timeago";

import { ScanItemStatus } from ".";
import { DatasetOverview } from "../../../store/interfaces/DatasetOverview.interface";
import { Link } from "../../Common";

type ScanItemProps = {
  scan: DatasetOverview | null;
};

const ScanItemOverview = ({ scan }: ScanItemProps) => {
  if (!scan) {
    return <></>;
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems={["flex-start", "center"]}
      flexDirection={["column", "row"]}
      flexWrap="wrap"
      sx={{
        borderLeftColor: "primary",
        borderLeftWidth: 10,
        borderLeftStyle: "solid",
      }}
      mb={4}
      px={4}
    >
      <Box
        py={2}
        width={["100%", "100%", "100%", "75%"]}
        sx={{ textTransform: "capitalize" }}
      >
        <Heading
          as="h3"
          mb={2}
          maxWidth={[240, "100%"]}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {decodeURIComponent(scan.description || "")}
        </Heading>
        <Flex alignItems="baseline" flexWrap="wrap" mb={[2, 0]}>
          <Text variant="caps" fontSize="2" width="180px">
            Scan uploaded:
          </Text>
          <Text minWidth="180px">
            <TimeAgo date={scan.uploadTimestamp || new Date()} />
          </Text>
        </Flex>
        <Flex alignItems="baseline" flexWrap="wrap" mb={[2, 0]}>
          <Text variant="caps" fontSize="2" width="180px">
            Current status:
          </Text>
          <Box minWidth="180px">
            <ScanItemStatus status={scan.processingStatus} />
          </Box>
        </Flex>
      </Box>
      <Box
        width={["100%", "100%", "100%", "25%"]}
        mb={[3, 0]}
        sx={{ textAlign: ["left", "left", "right", "right"] }}
      >
        <Link href={`/dashboard/scan/${scan.uploadTimestamp}`}>
          <Button variant="outline">See Details</Button>
        </Link>
      </Box>
    </Flex>
  );
};

export { ScanItemOverview };
