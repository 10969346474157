import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationsInterface {
  active: boolean;
  options: { body?: string; tag?: string };
  title: string;
}

const initialState: NotificationsInterface = {
  active: Notification.permission !== "default",
  options: {},
  title: "",
};

type PayloadSendNotification = {
  title: string;
  options: { body?: string; tag?: string };
};

const notifications = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    sendNotification: (
      state,
      { payload }: PayloadAction<PayloadSendNotification>
    ) => {
      state.options = payload.options;
      state.title = payload.title;
    },
    closeNotification: (state) => {
      state.options = {};
      state.title = "";
    },
    activateNotification: (state) => {
      state.active = true;
    },
  },
});

export const notificationsReducer = notifications.reducer;

export const {
  sendNotification,
  closeNotification,
  activateNotification,
} = notifications.actions;
