import { useCallback, useRef, useState } from "react";
import { ArrayBuffer as SparkArrayBuffer } from "spark-md5";
import axios from "axios";
import { toast } from "react-toastify";

import { DropzoneArea } from "./DropzoneArea";
import { getFormParams, getPresignedUrl, readFile } from "./helpers";
import ArchiveDescription from "./ArchiveDescription";
import { ProgressBar } from "../../Common";
import Buttons from "./Buttons";

export const ZIP_MIME = [
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
];

type FileUploadProps = {
  onSuccess: () => void;
};

const FileUpload = ({ onSuccess }: FileUploadProps) => {
  const terminate = useRef(() => {});

  const handleDrop = useCallback(async (acceptedFiles) => {
    setInProgress(true);

    try {
      const spark = new SparkArrayBuffer();

      setStatus("Adding files...");
      const archiveFile = await readFile(acceptedFiles[0]);
      spark.append(archiveFile);
      const archiveHash: string = spark.end();

      setArchiveFile(archiveFile);
      setArchiveHash(archiveHash);
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error("Something went wrong. Try again.");
        console.log(error); // eslint-disable-line
      }
    } finally {
      setInProgress(false);
    }
  }, []);

  const handleUpload = async () => {
    if (!archiveFile || !archiveHash) {
      return;
    }

    let terminated = false;
    setInProgress(true);

    try {
      setStatus("Sending files...");
      setProgress(0);
      const { fields, headers, presigned_url } = await getPresignedUrl(
        archiveHash
      );

      const formData = getFormParams(fields, archiveFile, archiveDescription);

      const source = axios.CancelToken.source();
      terminate.current = () => {
        terminated = true;
        source.cancel();
      };

      await axios.post(presigned_url, formData, {
        headers,
        cancelToken: source.token,
        onUploadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded / total) * 100);
          setProgress(progress);
          if (progress === 100) {
            setStatus("Finishing...");
          }
        },
      });

      if (terminated) {
        throw new axios.Cancel("cancelled");
      }

      onSuccess();
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error("Something went wrong. Try again.");
        console.log(error); // eslint-disable-line
      }
    } finally {
      setInProgress(false);
    }
  };

  const handleReset = () => {
    setArchiveFile(null);
    setArchiveHash(null);
  };

  const [status, setStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [archiveFile, setArchiveFile] = useState<Uint8Array | null>(null);
  const [archiveHash, setArchiveHash] = useState<string | null>(null);
  const [archiveDescription, setArchiveDescription] = useState<string>("");
  const [hasError, setError] = useState<boolean>(false);

  const handleTermination = () => {
    terminate.current();
    setInProgress(false);
    toast.warning("Operation has been cancelled.");
  };

  const handleChange = (name: string, error: boolean) => {
    setArchiveDescription(name);
    setError(error);
  };

  return (
    <>
      {inProgress ? (
        <ProgressBar status={status} progress={progress} mb={4} />
      ) : (
        <>
          <DropzoneArea
            dirSupport={false}
            caption="Drop zipped files with scan here..."
            captionDrag={
              <>
                Try dropping zipped files with scan here, or click to select
                file to upload.
                <br />
                Notice: change to desktop modern browser to be able to upload
                whole folder without zipping.
              </>
            }
            hasFiles={!!archiveFile}
            onDrop={handleDrop}
            dropzoneOptions={{ accept: ZIP_MIME.join(",") }}
          />
          <ArchiveDescription
            description={archiveDescription}
            onChange={handleChange}
          />
        </>
      )}

      <Buttons
        inProgress={inProgress}
        hasFiles={!!archiveFile}
        disabled={!archiveFile || inProgress || hasError}
        onUpload={handleUpload}
        onTerminate={handleTermination}
        onReset={handleReset}
      />
    </>
  );
};

export default FileUpload;
