import { createContext } from "react";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./styles/globals.css";
import "./styles/index.css";
import "react-toastify/dist/ReactToastify.min.css";

import setupAws from "./aws/config";
import useAuth, { AuthData } from "./components/Auth/useAuth";

import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import NotFound from "./pages/404";
import SecuredRoute from "./SecuredRoute";
import Preview from "./pages/dashboard/Preview";
import Start from "./pages/dashboard/Start";
import Archive from "./pages/dashboard/Archive";
import Scan from "./pages/dashboard/Scan";
import Current from "./pages/dashboard/Current";

export const AuthContext = createContext<AuthData>({
  user: null,
  userId: "",
  loading: false,
});

setupAws();

function App() {
  const authData = useAuth();

  return (
    <Router>
      <AuthContext.Provider value={authData}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/dashboard">
            <Redirect to="/dashboard/start" />
          </Route>
          <SecuredRoute
            exact
            path="/dashboard/start"
            component={Start}
            public={Preview}
          />
          <SecuredRoute exact path="/dashboard/archive" component={Archive} />
          <SecuredRoute exact path="/dashboard/current" component={Current} />
          <SecuredRoute path="/dashboard/scan/:path" component={Scan} />
          <Route component={NotFound} />
        </Switch>
        <ToastContainer position="top-center" autoClose={5000} />
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
