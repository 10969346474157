import { Text } from "rebass";
import { Link } from "../../Common";

const NoScan = () => {
  return (
    <Text textAlign="center" fontSize="3">
      Please upload your first scan. Go to{" "}
      <Link href="/dashboard/start" fontSize="3">
        Upload new scan
      </Link>
    </Text>
  );
};

export { NoScan };
