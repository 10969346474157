import { Text } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faHourglassHalf,
  faFileMedicalAlt,
} from "@fortawesome/free-solid-svg-icons";

import { WebUIProcessingStatus } from "../../../aws/API";
import theme from "../../../styles/theme";

const StatusLabels = {
  [WebUIProcessingStatus.COMPUTATION_FAILED]: "Computation failed",
  [WebUIProcessingStatus.COMPUTING]: "Computing",
  [WebUIProcessingStatus.READY]: "Ready",
};

const StatusIcons = {
  [WebUIProcessingStatus.COMPUTATION_FAILED]: {
    icon: faTimesCircle,
    color: theme.colors.error,
  },
  [WebUIProcessingStatus.COMPUTING]: {
    icon: faHourglassHalf,
    color: theme.colors.warning,
  },
  [WebUIProcessingStatus.READY]: {
    icon: faFileMedicalAlt,
    color: theme.colors.success,
  },
};

type ScanItemProps = {
  status: WebUIProcessingStatus;
};

const ScanItemStatus = ({ status }: ScanItemProps) => {
  return (
    <Text>
      <FontAwesomeIcon
        icon={StatusIcons[status].icon}
        color={StatusIcons[status].color}
      />{" "}
      {StatusLabels[status]}
    </Text>
  );
};

export { ScanItemStatus };
