import { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearScans } from "../../store/slices/datasets";

export type User = { email: string; email_verified: boolean; sub: string };
export type AuthData = {
  user: User | null;
  userId: string;
  loading: boolean;
};
export enum AuthEvent {
  SignIn = "signIn",
  SignOut = "signOut",
}

function useAuth() {
  const dispatch = useDispatch();
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUser(user.attributes);
        setUserId(user.attributes.sub);
      } catch (error) {
        setUser(null);
        setUserId("");
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    const authListener = (data: any) => {
      if (data.payload.event === AuthEvent.SignIn) {
        setUser(data.payload.data.attributes);
        setUserId(data.payload.data.attributes.sub);
      }

      if (data.payload.event === AuthEvent.SignOut) {
        toast.warning(data.payload.message);
        setUser(null);
        setUserId("");
        dispatch(clearScans());
      }
    };

    Hub.listen("auth", authListener);

    return () => {
      Hub.remove("auth", authListener);
    };
  }, []);

  return { user, userId, loading };
}

export default useAuth;
