import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { toast } from "react-toastify";

import { HtmlHead, Loader } from "../../components/Common";
import { Layout } from "../../components/Layout";
import { AuthContext } from "../../App";
import { DashboardNav } from "../../components/Dashboard";
import { ScanItemOverview, NoScan } from "../../components/Dashboard/Scan";
import { queryLastScan, setLastScan } from "../../store/slices/datasets";
import { RootState } from "../../store";
import { datasetDetailsChange } from "../../aws/graphql/subscriptions";
import {
  DatasetDetailsChangeSubscription,
  WebUIProcessingStatus,
} from "../../aws/API";
import Notifier from "../../components/Notifications/Notifier";
import { sendNotification } from "../../store/slices/notifications";

function Current() {
  const dispatch = useDispatch();
  const { userId } = useContext(AuthContext);

  const queryStatus = useSelector(
    (state: RootState) => state.datasets.queryStatus
  );
  const lastScan = useSelector((state: RootState) => state.datasets.lastScan);

  const isLoading = queryStatus === "pending" ? true : false;

  const handleNotificationSend = (status: WebUIProcessingStatus) => {
    const title = "CompareMRI";
    const options = {
      body: `Your scan examination status changed to: ${status}.`,
      tag: new Date().toISOString(),
    };

    dispatch(sendNotification({ title, options }));
  };

  useEffect(() => {
    dispatch(queryLastScan({ userId }));
  }, []);

  useEffect(() => {
    let subscription: any = null;

    const observable: any = API.graphql(
      graphqlOperation(datasetDetailsChange, { user_id: userId })
    );

    if (observable?.subscribe) {
      subscription = observable.subscribe({
        next: (result: { provider: any; value: GraphQLResult }) => {
          const { value } = result;
          const {
            datasetDetailsChange,
          } = value.data as DatasetDetailsChangeSubscription;

          if (!datasetDetailsChange) {
            return;
          }

          dispatch(
            setLastScan({
              lastScan: {
                userId: datasetDetailsChange.user_id,
                uploadTimestamp: datasetDetailsChange.upload_timestamp,
                description: datasetDetailsChange.description,
                processingStatus: datasetDetailsChange.webui_processing_status,
              },
            })
          );

          if (datasetDetailsChange.webui_processing_status) {
            handleNotificationSend(
              datasetDetailsChange.webui_processing_status
            );
          }
        },
        error: (error: any) => {
          toast.error(error.message);
        },
      });
    }

    return () => {
      if (subscription?.unsubscribe) {
        subscription.unsubscribe();
      }
    };
  }, [userId]);

  return (
    <>
      <HtmlHead title="Last scan details" />
      <Layout>
        <DashboardNav />
        {isLoading && !lastScan && <Loader caption="Loading data..." />}

        {!lastScan && !isLoading && <NoScan />}

        {lastScan && (
          <>
            <ScanItemOverview scan={lastScan} />
            <Notifier />
          </>
        )}
      </Layout>
    </>
  );
}

export default Current;
