import { useCallback, useRef, useState } from "react";
import { Zip, AsyncZipDeflate } from "fflate";
import { ArrayBuffer as SparkArrayBuffer } from "spark-md5";
import axios from "axios";
import { toast } from "react-toastify";

import { DropzoneArea } from "./DropzoneArea";
import {
  getFormParams,
  getPresignedUrl,
  readFile,
  getRelativePath,
} from "./helpers";
import ArchiveDescription from "./ArchiveDescription";
import { ProgressBar } from "../../Common";
import Buttons from "./Buttons";

function zipHandler(zippable: Zip) {
  return new Promise<Uint8Array>((resolve, reject) => {
    const chunks: any[] = [];
    let chunksSize: number = 0;

    zippable.ondata = (err, chunk, final) => {
      if (err) {
        reject(err);
      }

      chunks.push(chunk);
      chunksSize += chunk.length;

      if (final) {
        let currentOffset = 0;

        const archive = new Uint8Array(chunksSize);

        chunks.forEach((chunk) => {
          archive.set(chunk, currentOffset);
          currentOffset += chunk.length;
        });

        resolve(archive);
      }
    };
  });
}

type FolderUploadProps = {
  onSuccess: () => void;
};

const FolderUpload = ({ onSuccess }: FolderUploadProps) => {
  const terminate = useRef(() => {});

  const handleDrop = useCallback(async (acceptedFiles) => {
    let terminated = false;
    setInProgress(true);

    try {
      const spark = new SparkArrayBuffer();
      const zip = new Zip();
      const zipPromise = zipHandler(zip);
      const filesNumber: number = acceptedFiles.length;

      terminate.current = () => {
        zip.terminate();
        terminated = true;
      };

      setStatus("Zipping files...");
      for (const [index, file] of acceptedFiles.entries()) {
        if (terminated) {
          throw new axios.Cancel("cancelled");
        }

        const fileBuffer = await readFile(file);
        spark.append(fileBuffer);

        const filePath = file.webkitRelativePath || getRelativePath(file.path);

        const fileDeflate = new AsyncZipDeflate(filePath);
        zip.add(fileDeflate);
        fileDeflate.push(fileBuffer, true);

        setProgress(Math.round((index / filesNumber) * 100));
      }

      zip.end();

      const archiveHash: string = spark.end();
      const archiveFile = await zipPromise;

      setArchiveFile(archiveFile);
      setArchiveHash(archiveHash);
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error("Something went wrong. Try again.");
        console.log(error); // eslint-disable-line
      }
    } finally {
      setInProgress(false);
    }
  }, []);

  const handleUpload = async () => {
    if (!archiveFile || !archiveHash) {
      return;
    }

    let terminated = false;
    setInProgress(true);

    try {
      terminate.current = () => {
        terminated = true;
      };

      setStatus("Sending files...");
      setProgress(0);
      const { fields, headers, presigned_url } = await getPresignedUrl(
        archiveHash
      );

      const formData = getFormParams(fields, archiveFile, archiveDescription);

      const source = axios.CancelToken.source();
      terminate.current = () => {
        terminated = true;
        source.cancel();
      };

      await axios.post(presigned_url, formData, {
        headers,
        cancelToken: source.token,
        onUploadProgress: ({ loaded, total }) => {
          const progress = Math.round((loaded / total) * 100);
          setProgress(progress);
          if (progress === 100) {
            setStatus("Finishing...");
          }
        },
      });

      if (terminated) {
        throw new axios.Cancel("cancelled");
      }

      onSuccess();
    } catch (error) {
      if (!axios.isCancel(error)) {
        toast.error("Something went wrong. Try again.");
        console.log(error); // eslint-disable-line
      }
    } finally {
      setInProgress(false);
    }
  };

  const handleReset = () => {
    setArchiveFile(null);
    setArchiveHash(null);
  };

  const [status, setStatus] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [archiveFile, setArchiveFile] = useState<Uint8Array | null>(null);
  const [archiveHash, setArchiveHash] = useState<string | null>(null);
  const [archiveDescription, setArchiveDescription] = useState<string>("");
  const [hasError, setError] = useState<boolean>(false);

  const handleTermination = () => {
    terminate.current();
    setInProgress(false);
    toast.warning("Operation has been cancelled.");
  };

  const handleChange = (name: string, error: boolean) => {
    setArchiveDescription(name);
    setError(error);
  };

  return (
    <>
      {inProgress ? (
        <ProgressBar status={status} progress={progress} mb={4} />
      ) : (
        <>
          <DropzoneArea
            caption="Try dropping folder with files here, or click to select folder to upload."
            captionDrag="Drop folder with files here..."
            hasFiles={!!archiveFile}
            onDrop={handleDrop}
          />
          <ArchiveDescription
            description={archiveDescription}
            onChange={handleChange}
          />
        </>
      )}

      <Buttons
        inProgress={inProgress}
        hasFiles={!!archiveFile}
        disabled={!archiveFile || inProgress || hasError}
        onUpload={handleUpload}
        onTerminate={handleTermination}
        onReset={handleReset}
      />
    </>
  );
};

export default FolderUpload;
