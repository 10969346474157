import { configureStore } from "@reduxjs/toolkit";

import { authReducer } from "./slices/auth";
import { datasetsReducer } from "./slices/datasets";
import { notificationsReducer } from "./slices/notifications";

const reducer = {
  auth: authReducer,
  datasets: datasetsReducer,
  notifications: notificationsReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
