/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const requestDatasetDetails = /* GraphQL */ `
  query RequestDatasetDetails($upload_timestamp: String!, $user_id: String!) {
    requestDatasetDetails(
      upload_timestamp: $upload_timestamp
      user_id: $user_id
    ) {
      description
      progress_percent
      report_url
      upload_timestamp
      user_id
      webui_processing_status
    }
  }
`;
export const requestDatasetUpload = /* GraphQL */ `
  query RequestDatasetUpload($dataset_md5: String!) {
    requestDatasetUpload(dataset_md5: $dataset_md5) {
      fields {
        AWSAccessKeyId
        acl
        key
        policy
        signature
        x_amz_security_token
      }
      headers {
        Content_MD5
      }
      presigned_url
    }
  }
`;
export const requestUserDatasetsList = /* GraphQL */ `
  query RequestUserDatasetsList(
    $limit: Int
    $next_token: String
    $order_by: DatasetsListSortDirection
    $user_id: String!
  ) {
    requestUserDatasetsList(
      limit: $limit
      next_token: $next_token
      order_by: $order_by
      user_id: $user_id
    ) {
      datasets_overviews {
        description
        upload_timestamp
        user_id
        webui_processing_status
      }
      next_token
    }
  }
`;
