import { string, object, ref } from "yup";

// regex for password with special character also:
// /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/
export const signUpSchema = object().shape({
  username: string().email("Invalid email.").required("Required."),
  password: string()
    .matches(
      /(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*[^A-Za-z0-9]))(?=(.*)).{8,}/,
      "Password must contain upper and lower case letter, a number, a special character and must be at least 8 characters long."
    )
    .required("No password provided."),
  passwordConfirm: string().oneOf(
    [ref("password"), null],
    "Passwords must match."
  ),
});

export const signInSchema = object().shape({
  username: string().email("Invalid username.").required("Required."),
  password: string().required("No password provided."),
});

export const confirmSignInSchema = object().shape({
  username: string().email("Invalid username.").required("Required."),
  code: string().required("Enter valid code."),
});

export const forgotSendSchema = object().shape({
  username: string().email("Invalid username.").required("Required."),
});

export const forgotSubmitSchema = object().shape({
  username: string().email("Invalid username.").required("Required."),
  code: string().required("Enter valid code."),
  password: string()
    .matches(
      /(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*[^A-Za-z0-9]))(?=(.*)).{8,}/,
      "Password must contain upper and lower case letter, a number, a special character and must be at least 8 characters long."
    )
    .required("No password provided."),
  passwordConfirm: string().oneOf(
    [ref("password"), null],
    "Passwords must match."
  ),
});
