import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthInterface {
  authState: AuthState;
  userEmail?: string;
}

export enum AuthState {
  SignIn = "signin",
  Loading = "loading",
  SignedOut = "signedout",
  SignedIn = "signedin",
  SigningUp = "signingup",
  ConfirmSignUp = "confirmSignUp",
  ConfirmingSignUpCustomFlow = "confirmsignupcustomflow",
  ConfirmSignIn = "confirmSignIn",
  ConfirmingSignInCustomFlow = "confirmingsignincustomflow",
  VerifyingAttributes = "verifyingattributes",
  ForgotPassword = "forgotpassword",
  ResetPassword = "resettingpassword",
  SettingMFA = "settingMFA",
  TOTPSetup = "TOTPSetup",
  CustomConfirmSignIn = "customConfirmSignIn",
  VerifyContact = "verifyContact",
}

type PayloadChangeState = {
  authState: AuthState;
  userEmail?: string;
};

const initialState: AuthInterface = {
  authState: AuthState.SignIn,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    changeState: (state, { payload }: PayloadAction<PayloadChangeState>) => {
      state.authState = payload.authState;
      state.userEmail = payload.userEmail;
    },
  },
});

export const authReducer = auth.reducer;

export const { changeState } = auth.actions;
