import { Helmet } from "react-helmet";

type HtmlHeadProps = {
  title?: string;
  fullTitle?: string;
  desc?: string;
};

const HtmlHead = ({ title, fullTitle, desc }: HtmlHeadProps) => {
  return (
    <Helmet>
      <title></title>
      <title>{fullTitle || `${title} | Kardiolytics`}</title>
      <meta
        name="Description"
        content={
          desc || "Kardiolytics VCAST virtual coronary blood flow analysis CT"
        }
      ></meta>
      <link rel="icon" href="/favicon.ico" />
    </Helmet>
  );
};

export { HtmlHead };
