import { ReactNode } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { Box, Flex } from "rebass";
import { faFileArchive } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

type DropzoneAreaProps = {
  dirSupport?: boolean;
  hasFiles: boolean;
  caption: string | ReactNode;
  captionDrag?: string | ReactNode;
  dropzoneOptions?: DropzoneOptions;
  onDrop: (value: File[]) => void;
};

const DropzoneArea = ({
  dirSupport = true,
  hasFiles,
  caption,
  captionDrag,
  dropzoneOptions = {},
  onDrop,
}: DropzoneAreaProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  return (
    <Box mb={4}>
      {hasFiles && (
        <>
          <FontAwesomeIcon icon={faFileArchive} size="lg" /> Archive has been
          added
        </>
      )}
      {!hasFiles && (
        /* @ts-expect-error */
        <Flex
          {...getRootProps()}
          sx={{
            borderWidth: "2px",
            borderStyle: "dashed",
            borderColor: isDragActive ? "primary" : "fadingDark",
            borderRadius: 4,
            justifyContent: "center",
            alignItems: "center",
            color: "primary",
            height: "100px",
            cursor: "pointer",
          }}
        >
          {dirSupport ? (
            <input
              {...getInputProps()}
              /* @ts-expect-error */
              webkitdirectory="true"
            />
          ) : (
            <input {...getInputProps()} />
          )}

          <FontAwesomeIcon icon={faUpload} size="lg" />
          <Box ml={2}>{isDragActive ? captionDrag : caption}</Box>
        </Flex>
      )}
    </Box>
  );
};

export { DropzoneArea };
