import { Box, Heading } from "rebass";
import { HtmlHead } from "../../components/Common";
import { DashboardNav, Uploader } from "../../components/Dashboard";
import { Layout, Paragraph } from "../../components/Layout";

function Start() {
  return (
    <>
      <HtmlHead title="Upload" />
      <Layout>
        <DashboardNav />
        <Paragraph>
          <Heading variant="large" as="h1">
            Scan upload
          </Heading>

          <Box pb={4}>
            <Heading>Upload instructions</Heading>
            <ul>
              <li>Choose folder with scan files.</li>
              <li>Drag and drop files on marked zone.</li>
              <li>
                Click &quot;Upload&quot; button and wait until all files are
                uploaded to the cloud. Do not close the application during
                upload.
              </li>
            </ul>
          </Box>
          <Uploader />
        </Paragraph>
      </Layout>
    </>
  );
}

export default Start;
