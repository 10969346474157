import { Auth } from "aws-amplify";
import { Box, Button, Flex, Heading, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { AuthState, changeState } from "../../store/slices/auth";
import { forgotSendSchema } from "./schema";
import { useState } from "react";
import { HtmlHead, Loader } from "../Common";
import { RootState } from "../../store";

type FormType = {
  username: string;
};

function ForgotPassword() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(forgotSendSchema),
  });

  const userEmail = useSelector((state: RootState) => state.auth.userEmail);
  const [loading, setLoading] = useState<boolean>(false);

  const forgotPassword = async ({ username }: FormType) => {
    setLoading(true);
    toast.dismiss();

    try {
      await Auth.forgotPassword(username);
      toast.warning(`Verification code has been sent to your email`);
      dispatch(
        changeState({ authState: AuthState.ResetPassword, userEmail: username })
      );
    } catch (error) {
      toast.error(error.message, { autoClose: 10000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HtmlHead title="Forgot Password" />

      <Box
        py={2}
        px={2}
        mx="auto"
        width={["100%", "75%", "75%", "50%"]}
        as="form"
        sx={{ position: "relative" }}
        onSubmit={handleSubmit(forgotPassword)}
      >
        {loading && <Loader overlay={true} />}
        <Heading variant="large" as="h1">
          Send verification code
        </Heading>
        <Box pb={4}>
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="user@example.com"
            ref={register()}
            defaultValue={userEmail}
            variant={errors.username ? "error" : "input"}
          />
          <Text variant="formError">{errors.username?.message}</Text>
        </Box>

        <Text pb={2}>
          Already have the code?{" "}
          <Button
            variant="link"
            type="button"
            onClick={() =>
              dispatch(changeState({ authState: AuthState.ResetPassword }))
            }
          >
            Reset your password
          </Button>
        </Text>

        <Flex alignItems="center" py={2}>
          <Text width={1 / 2}>
            <Button
              variant="link"
              type="button"
              onClick={() =>
                dispatch(changeState({ authState: AuthState.SignIn }))
              }
            >
              Back to Sign In
            </Button>
          </Text>

          <Box width={1 / 2} sx={{ textAlign: "right" }}>
            <Button type="submit" disabled={loading}>
              Send Code
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export { ForgotPassword };
