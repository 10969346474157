import { Button, Heading, Text } from "rebass";

import { HtmlHead } from "../../components/Common";
import { Layout } from "../../components/Layout";
import { Paragraph } from "../../components/Layout";
import { Link } from "../../components/Common";

function Preview() {
  return (
    <>
      <HtmlHead title="Dashboard" />
      <Layout>
        <Paragraph>
          <Heading variant="large" as="h1">
            Upload your MRI
          </Heading>
          <Text>
            Kardiolytics allows you to learn more about your brain health. We
            have developed an application that allows for automatic
            identification of a variety of anatomical structures within your
            brain (eg. hippocampus, ventricles, corpus callosum). The process is
            called segmentation and it allows us to extract a specific
            anatomical region within your brain and compare it to a large
            database of healthy and diseased brains. The application can also
            estimate your brain age, check for signs of dementia, brain injury,
            and assess your brain performance, all of which have been shown to
            be a predictor for overall health and wellbeing. The results if this
            analysis information should be consulted with your doctor. Currently
            this application is not approved by the U.S. Food and Drug
            Administration for diagnosis or treatment.
          </Text>

          <Link href="/login">
            <Button my={3}>Start!</Button>
          </Link>
        </Paragraph>
      </Layout>
    </>
  );
}

export default Preview;
