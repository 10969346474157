import { ReactNode } from "react";
import { Link as RebassLink } from "rebass";
import { useHistory } from "react-router-dom";
import cn from "classnames";

type LinkProps = {
  children: ReactNode;
  href: string;
  active?: boolean;
  variant?: string;
  ariaLabel?: string;
  fontSize?: string;
  absolute?: boolean;
  newWindow?: boolean;
};

const Link = ({
  children,
  href,
  active,
  variant = "link",
  ariaLabel,
  fontSize,
  absolute = false,
  newWindow = false,
}: LinkProps) => {
  const history = useHistory();

  const handleRedirect = (e: any) => {
    e.preventDefault();

    if (absolute) {
      window.open(href, newWindow ? "_blank" : "_self");
    } else {
      history.push(href);
    }
  };

  return (
    <RebassLink
      href={href}
      variant={variant}
      aria-label={ariaLabel}
      className={cn({ active })}
      fontSize={fontSize}
      onClick={handleRedirect}
    >
      {children}
    </RebassLink>
  );
};

export { Link };
