import { useContext, useEffect } from "react";
import { Box, Button } from "rebass";

import { RootState } from "../../store";
import { HtmlHead, Loader } from "../../components/Common";
import { Layout } from "../../components/Layout";
import { AuthContext } from "../../App";
import { DashboardNav } from "../../components/Dashboard";
import { NoScan, ScanList } from "../../components/Dashboard/Scan";
import { trimScans, queryScans } from "../../store/slices/datasets";
import { useDispatch, useSelector } from "react-redux";

function Archive() {
  const dispatch = useDispatch();
  const { userId } = useContext(AuthContext);

  const { scans, nextToken, queryStatus } = useSelector(
    (state: RootState) => state.datasets
  );

  const isLoading = queryStatus === "pending" ? true : false;

  useEffect(() => {
    dispatch(queryScans({ userId, nextToken: null }));

    return () => {
      dispatch(trimScans());
    };
  }, []);

  return (
    <>
      <HtmlHead title="List of uploaded scans" />
      <Layout>
        <DashboardNav />

        {!scans.length && !isLoading && <NoScan />}

        {!!scans.length && <ScanList scans={scans} />}

        {nextToken && !isLoading && (
          <Box sx={{ textAlign: "center" }}>
            <Button
              variant="outline"
              onClick={() => dispatch(queryScans({ userId, nextToken }))}
            >
              Load more...
            </Button>
          </Box>
        )}

        {isLoading && <Loader caption="Loading data..." />}
      </Layout>
    </>
  );
}

export default Archive;
