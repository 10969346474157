import { Box, Text } from "rebass";

type ProgressBarProps = {
  status?: string;
  progress: number;
  mb?: string | number;
};

const ProgressBar = ({ status, progress, mb }: ProgressBarProps) => {
  return (
    <Box mb={mb}>
      {status && <Text mb={1}>{status}</Text>}
      <Box width={1} height="10px" backgroundColor="fading">
        <Box
          width={`${progress}%`}
          height="100%"
          backgroundColor="primary"
        ></Box>
      </Box>
    </Box>
  );
};

export { ProgressBar };
