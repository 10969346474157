/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const datasetDetailsChange = /* GraphQL */ `
  subscription DatasetDetailsChange($user_id: String!) {
    datasetDetailsChange(user_id: $user_id) {
      description
      progress_percent
      report_url
      upload_timestamp
      user_id
      webui_processing_status
    }
  }
`;
