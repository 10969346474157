import { Amplify } from "aws-amplify";
import { AWS_APPSYNC_AUTHENTICATION_TYPE } from "../store/constants";

const setupAws = () => {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_COGNITO_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    },
    API: {
      aws_appsync_graphqlEndpoint:
        process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
      aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
      aws_appsync_authenticationType: AWS_APPSYNC_AUTHENTICATION_TYPE,
    },
  });
};

export default setupAws;
