import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Notification from "react-web-notification";
import { closeNotification } from "../../store/slices/notifications";

const Notifications = () => {
  const dispatch = useDispatch();
  const { active, options, title } = useSelector(
    (state) => state.notifications
  );

  function handleNotSupported() {
    toast.warn("Web Notification not Supported by your browser");
  }

  function handleNotificationOnError(e, tag) {
    console.error(e, "Notification error tag:" + tag); // eslint-disable-line no-console
    toast.error("Notification error.");
  }

  return (
    <div>
      {active && (
        <Notification
          askAgain
          notSupported={handleNotSupported}
          onClose={() => dispatch(closeNotification())}
          onError={handleNotificationOnError}
          timeout={0}
          title={title}
          options={options}
        />
      )}
    </div>
  );
};

export default Notifications;
