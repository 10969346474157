import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { requestDatasetUpload } from "../../../aws/graphql/queries";
import { RequestDatasetUploadQuery } from "../../../aws/API";

export function readFile(file: File) {
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(new Uint8Array(reader.result as ArrayBuffer));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function getPresignedUrl(datasetMd5: string) {
  const { data } = (await API.graphql(
    graphqlOperation(requestDatasetUpload, { dataset_md5: datasetMd5 })
  )) as GraphQLResult;

  const {
    requestDatasetUpload: { fields, headers, presigned_url },
  } = data as RequestDatasetUploadQuery;

  return { fields, headers, presigned_url };
}

export function getFormParams(
  fields: { [key: string]: string },
  fileBuffer: Uint8Array,
  archiveDescription: string
): FormData {
  const formData = new FormData();

  formData.append("acl", fields["acl"]);
  formData.append("key", fields["key"]);
  formData.append("AWSAccessKeyId", fields["AWSAccessKeyId"]);
  formData.append("x-amz-security-token", fields["x_amz_security_token"]);
  formData.append("policy", fields["policy"]);
  formData.append("signature", fields["signature"]);
  formData.append(
    "x-amz-meta-description",
    encodeURIComponent(archiveDescription)
  );
  formData.append("file", new Blob([fileBuffer]));

  return formData;
}

export function getRelativePath(path: string) {
  if (path[0] === "/" || path[0] === "\\") {
    return path.substring(1);
  }

  return path;
}
