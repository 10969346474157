import { Auth } from "aws-amplify";
import { Box, Button, Flex, Heading, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { AuthState, changeState } from "../../store/slices/auth";
import { forgotSubmitSchema } from "./schema";
import { useState } from "react";
import { HtmlHead, Loader } from "../Common";
import { RootState } from "../../store";

type FormType = {
  username: string;
  code: string;
  password: string;
  passwordConfirm: string;
};

function ResetPassword() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(forgotSubmitSchema),
  });

  const userEmail = useSelector((state: RootState) => state.auth.userEmail);
  const [loading, setLoading] = useState<boolean>(false);

  const resetPassword = async ({ username, code, password }: FormType) => {
    setLoading(true);
    toast.dismiss();

    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      toast.success(`Your password has been changed.`);
      dispatch(changeState({ authState: AuthState.SignIn }));
    } catch (error) {
      toast.error(error.message, { autoClose: 10000 });
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = loading || !!Object.keys(errors).length;

  return (
    <>
      <HtmlHead title="Reset Password" />

      <Box
        py={2}
        px={2}
        mx="auto"
        width={["100%", "75%", "75%", "50%"]}
        as="form"
        sx={{ position: "relative" }}
        onSubmit={handleSubmit(resetPassword)}
      >
        {loading && <Loader overlay={true} />}
        <Heading variant="large" as="h1">
          Send verification code
        </Heading>
        <Box pb={2}>
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="user@example.com"
            defaultValue={userEmail}
            readOnly={!!userEmail}
            ref={register()}
            variant={errors.username ? "error" : "input"}
          />
          <Text variant="formError">{errors.username?.message}</Text>
        </Box>

        <Box pb={2}>
          <Label htmlFor="code">Code</Label>
          <Input
            id="code"
            name="code"
            type="text"
            placeholder="received code"
            ref={register()}
            variant={errors.code ? "error" : "input"}
          />
          <Text variant="formError">{errors.code?.message}</Text>
        </Box>

        <Box pb={2}>
          <Label htmlFor="password">New password</Label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="new password"
            autoComplete="on"
            ref={register()}
            variant={errors.password ? "error" : "input"}
          />
          <Text variant="formError">{errors.password?.message}</Text>
        </Box>

        <Box pb={4}>
          <Label htmlFor="password">Repeat new password</Label>
          <Input
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            placeholder="new password"
            autoComplete="off"
            ref={register()}
            variant={errors.passwordConfirm ? "error" : "input"}
          />
          <Text variant="formError">{errors.passwordConfirm?.message}</Text>
        </Box>

        <Text pb={2}>
          Didn&apos;t receive the code?{" "}
          <Button
            variant="link"
            type="button"
            onClick={() =>
              dispatch(
                changeState({ authState: AuthState.ForgotPassword, userEmail })
              )
            }
          >
            Resend
          </Button>
        </Text>

        <Flex alignItems="center" py={2}>
          <Text width={1 / 2}>
            <Button
              variant="link"
              type="button"
              onClick={() =>
                dispatch(changeState({ authState: AuthState.SignIn }))
              }
            >
              Back to Sign In
            </Button>
          </Text>

          <Box width={1 / 2} sx={{ textAlign: "right" }}>
            <Button type="submit" disabled={isDisabled}>
              Reset
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export { ResetPassword };
