export default {
  breakpoints: ["576px", "768px", "992px", "1200px"],
  colors: {
    text: "#3f3f3f",
    inversed: "#fcfefe",
    background: "#fcfefe",
    primary: "#2C9F85",
    secondary: "#f76b8a",
    fading: "#eaf6f6",
    opacity: "#34495e",
    error: "#ff6060",
    warning: "#fdd835",
    success: "#00818f",
    primaryDark: "#237f6a",
    secondaryDark: "#f32754",
    fadingDark: "#c8e7e7",
    fadingLight: "#f3f9f9",
  },
  fonts: {
    body: "Didact Gothic, system-ui, sans-serif",
    heading: "Didact Gothic",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.5,
  },
  buttons: {
    primary: {
      fontSize: 3,
      fontWeight: "normal",
      color: "background",
      bg: "primary",
      borderRadius: 6,
      px: 4,
      py: 2,
      transition: "0.3s",
      cursor: "pointer",
      ":hover,:focus,.active": {
        bg: "primaryDark",
      },
      ":disabled": {
        bg: "fadingDark",
        cursor: "not-allowed",
      },
    },
    outline: {
      variant: "buttons.primary",
      color: "primary",
      bg: "transparent",
      boxShadow: "inset 0 0 2px",
      ":hover,:focus,:active": {
        color: "white",
      },
    },
    secondary: {
      variant: "buttons.primary",
      color: "background",
      bg: "secondary",
      ":hover,:focus,.active": {
        bg: "secondaryDark",
      },
    },
    link: {
      color: "primary",
      bg: "transparent",
      cursor: "pointer",
      padding: 0,
      borderRadius: 0,
      verticalAlign: "text-top",
      textDecoration: "underline",
      ":hover,:focus,.active": {
        color: "primary",
        textDecoration: "none",
      },
    },
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    display: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [5, 6, 7],
    },
    large: {
      color: "primary",
      fontSize: [5],
      py: 2,
    },
    xlarge: {
      color: "text",
      fontSize: [5, 5, 6],
      py: 2,
    },
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
    error: {
      color: "error",
    },
    formError: {
      color: "error",
      fontSize: 1,
    },
  },
  variants: {
    container: {
      mx: "auto",
      maxWidth: ["100%", "540px", "720px", "960px", "1140px"],
    },
    link: {
      color: "primary",
      cursor: "pointer",
      textDecoration: "underline",
      ":hover": {
        textDecoration: "none",
      },
    },
    tab: {
      textDecoration: "none",
      color: "primary",
      px: 4,
      py: 2,
      borderBottomWidth: 1,
      borderBottomColor: "primary",
      borderBottomStyle: "solid",
      ":hover,&.active": {
        borderBottomWidth: 3,
        textDecoration: "none",
        color: "primary",
      },
    },
  },
  forms: {
    input: {
      color: "primary",
      borderRadius: 0,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      backgroundColor: "fadingLight",
      ":focus,:active": {
        backgroundColor: "background",
        outline: "none",
      },
      "::placeholder": {
        color: "fadingDark",
      },
    },
    select: {},
    textarea: {
      color: "primary",
      borderRadius: 0,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      backgroundColor: "fadingLight",
      ":focus,:active": {
        backgroundColor: "background",
        outline: "none",
      },
      "::placeholder": {
        color: "fadingDark",
      },
    },
    label: {
      py: 1,
    },
    radio: {},
    checkbox: {},
    error: {
      color: "error",
      borderRadius: 0,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
      backgroundColor: "fading",
      ":focus,:active": {
        backgroundColor: "background",
        outline: "none",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
  },
};
