/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export enum WebUIProcessingStatus {
  COMPUTATION_FAILED = "COMPUTATION_FAILED",
  COMPUTING = "COMPUTING",
  READY = "READY",
}

export type DatasetDetails = {
  __typename: "DatasetDetails";
  description?: string;
  progress_percent?: number;
  report_url?: string | null;
  upload_timestamp?: string;
  user_id?: string;
  webui_processing_status?: WebUIProcessingStatus;
};

export type S3File = {
  __typename: "S3File";
  fields?: UploadURLFields;
  headers?: UploadURLHeaders;
  presigned_url?: string;
};

export type UploadURLFields = {
  __typename: "UploadURLFields";
  AWSAccessKeyId?: string;
  acl?: string;
  key?: string;
  policy?: string;
  signature?: string;
  x_amz_security_token?: string;
};

export type UploadURLHeaders = {
  __typename: "UploadURLHeaders";
  Content_MD5?: string;
};

export enum DatasetsListSortDirection {
  UPLOAD_TIMESTAMP_ASC = "UPLOAD_TIMESTAMP_ASC",
  UPLOAD_TIMESTAMP_DESC = "UPLOAD_TIMESTAMP_DESC",
}

export type PaginatedDatasetsOverviews = {
  __typename: "PaginatedDatasetsOverviews";
  datasets_overviews?: Array<DatasetOverview>;
  next_token?: string | null;
};

export type DatasetOverview = {
  __typename: "DatasetOverview";
  description?: string;
  upload_timestamp?: string;
  user_id?: string;
  webui_processing_status?: WebUIProcessingStatus;
};

export type DatasetDetailsChangeDummyMutationVariables = {
  description?: string;
  progress_percent?: number;
  report_url?: string | null;
  upload_timestamp?: string;
  user_id?: string;
  webui_processing_status?: WebUIProcessingStatus;
};

export type DatasetDetailsChangeDummyMutation = {
  datasetDetailsChangeDummy: {
    __typename: "DatasetDetails";
    description: string;
    progress_percent: number;
    report_url?: string | null;
    upload_timestamp: string;
    user_id: string;
    webui_processing_status: WebUIProcessingStatus;
  };
};

export type RequestDatasetDetailsQueryVariables = {
  upload_timestamp?: string;
  user_id?: string;
};

export type RequestDatasetDetailsQuery = {
  requestDatasetDetails?: {
    __typename: "DatasetDetails";
    description: string;
    progress_percent: number;
    report_url?: string | null;
    upload_timestamp: string;
    user_id: string;
    webui_processing_status: WebUIProcessingStatus;
  } | null;
};

export type RequestDatasetUploadQueryVariables = {
  dataset_md5?: string;
};

export type RequestDatasetUploadQuery = {
  requestDatasetUpload: {
    __typename: "S3File";
    fields: {
      __typename: "UploadURLFields";
      AWSAccessKeyId: string;
      acl: string;
      key: string;
      policy: string;
      signature: string;
      x_amz_security_token: string;
    };
    headers: {
      __typename: "UploadURLHeaders";
      Content_MD5: string;
    };
    presigned_url: string;
  };
};

export type RequestUserDatasetsListQueryVariables = {
  limit?: number | null;
  next_token?: string | null;
  order_by?: DatasetsListSortDirection | null;
  user_id?: string;
};

export type RequestUserDatasetsListQuery = {
  requestUserDatasetsList: {
    __typename: "PaginatedDatasetsOverviews";
    datasets_overviews: Array<{
      __typename: "DatasetOverview";
      description: string;
      upload_timestamp: string;
      user_id: string;
      webui_processing_status: WebUIProcessingStatus;
    }>;
    next_token?: string | null;
  };
};

export type DatasetDetailsChangeSubscriptionVariables = {
  user_id?: string;
};

export type DatasetDetailsChangeSubscription = {
  datasetDetailsChange?: {
    __typename: "DatasetDetails";
    description: string;
    progress_percent: number;
    report_url?: string | null;
    upload_timestamp: string;
    user_id: string;
    webui_processing_status: WebUIProcessingStatus;
  } | null;
};
