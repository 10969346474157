import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Heading, Image } from "rebass";
import { HtmlHead } from "../components/Common";
import { Layout } from "../components/Layout/Layout";
import { Paragraph } from "../components/Layout/Paragraph";

function HomePage() {
  const history = useHistory();

  return (
    <>
      <HtmlHead title="Home" />
      <Layout>
        <Paragraph>
          <Flex alignItems="center">
            <Image
              display={["none", "none", "none", "flex"]}
              width={[0, 0, 0, 1 / 3, 1 / 3]}
              src="/heart.png"
              maxWidth={320}
            ></Image>
            <Box
              width={[1, 1, 1, 2 / 3, 2 / 3]}
              sx={{ textAlign: ["center", "left", "left"] }}
            >
              <Heading variant="xlarge" as="h1" pb={4}>
                Artificial Intelligence analysis of&nbsp;MRI
              </Heading>
              <Button onClick={() => history.push("/dashboard")}>Start!</Button>
            </Box>
          </Flex>
        </Paragraph>
      </Layout>
    </>
  );
}

export default HomePage;
