import { Box, Flex, Text } from "rebass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import theme from "../../styles/theme";

type PagerProps = {
  numPages: number;
  pageNumber: number;
  onPageChange: (v: number) => void;
};

const Pager = ({ numPages, pageNumber, onPageChange }: PagerProps) => {
  const isFirstPage: boolean = pageNumber === 1;
  const isLastPage: boolean = pageNumber >= numPages;

  const setNextPage = () => {
    if (!isLastPage) {
      onPageChange(pageNumber + 1);
    }
  };

  const setPrevPage = () => {
    if (!isFirstPage) {
      onPageChange(pageNumber - 1);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      sx={{ textAlign: "center" }}
      my={2}
    >
      <Box
        display="inline-block"
        mr={2}
        sx={{ cursor: isFirstPage ? "default" : "pointer" }}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color={isFirstPage ? theme.colors.fading : theme.colors.success}
          size="2x"
          onClick={setPrevPage}
        />
      </Box>
      <Text fontSize={3}>
        {pageNumber} of {numPages}
      </Text>
      <Box
        display="inline-block"
        ml={2}
        sx={{ cursor: isLastPage ? "default" : "pointer" }}
      >
        <FontAwesomeIcon
          icon={faArrowRight}
          color={isLastPage ? theme.colors.fading : theme.colors.success}
          size="2x"
          onClick={setNextPage}
        />
      </Box>
    </Flex>
  );
};

export { Pager };
