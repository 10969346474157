import { Auth } from "aws-amplify";
import { Box, Button, Flex, Heading, Text } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { AuthState, changeState } from "../../store/slices/auth";
import { signUpSchema } from "./schema";
import { useState } from "react";
import { HtmlHead, Loader } from "../Common";

type FormType = {
  username: string;
  password: string;
  passwordConfirm: string;
};

function SignUp() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    mode: "onBlur",
    resolver: yupResolver(signUpSchema),
  });

  const [loading, setLoading] = useState<boolean>(false);

  const signUp = async ({ username, password }: FormType) => {
    setLoading(true);
    toast.dismiss();

    try {
      await Auth.signUp({ username, password });
      dispatch(changeState({ authState: AuthState.SignIn }));
      toast.success(
        "Your account has been created. Please check your email for confirmation.",
        { autoClose: 20000 }
      );
    } catch (error) {
      toast.error(error.message, { autoClose: 10000 });
    } finally {
      setLoading(false);
    }
  };

  const isDisabled = loading || !!Object.keys(errors).length;

  return (
    <>
      <HtmlHead title="Sign Up" />

      <Box
        py={2}
        px={2}
        mx="auto"
        width={["100%", "75%", "75%", "50%"]}
        as="form"
        sx={{ position: "relative" }}
        onSubmit={handleSubmit(signUp)}
      >
        {loading && <Loader overlay={true} />}
        <Heading variant="large" as="h1">
          Create a new account
        </Heading>
        <Box pb={2}>
          <Label htmlFor="username">Email</Label>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="user@example.com"
            ref={register()}
            variant={errors.username ? "error" : "input"}
          />
          <Text variant="formError">{errors.username?.message}</Text>
        </Box>
        <Box pb={2}>
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="your password"
            autoComplete="on"
            ref={register()}
            variant={errors.password ? "error" : "input"}
          />
          <Text variant="formError">{errors.password?.message}</Text>
        </Box>
        <Box pb={4}>
          <Label htmlFor="passwordConfirm">Repeat password</Label>
          <Input
            id="passwordConfirm"
            name="passwordConfirm"
            type="password"
            placeholder="your password"
            autoComplete="off"
            ref={register()}
            variant={errors.passwordConfirm ? "error" : "input"}
          />
          <Text variant="formError">{errors.passwordConfirm?.message}</Text>
        </Box>

        <Flex alignItems="center" pb={2}>
          <Text width={1 / 2}>
            Have an account?{" "}
            <Button
              variant="link"
              type="button"
              onClick={() =>
                dispatch(changeState({ authState: AuthState.SignIn }))
              }
            >
              Sign in
            </Button>
          </Text>

          <Box width={1 / 2} sx={{ textAlign: "right" }}>
            <Button type="submit" disabled={isDisabled}>
              Create
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export { SignUp };
