import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import FileUpload from "./FileUpload";
import FolderUpload from "./FolderUpload";

const Uploader = () => {
  const history = useHistory();
  const [dirUpSupport, setDirUpSupport] = useState<boolean>(false);

  useEffect(() => {
    const tmpInput = document.createElement("input");
    if ("webkitdirectory" in tmpInput) {
      setDirUpSupport(true);
    } else {
      setDirUpSupport(false);
    }
  }, []);

  const handleSuccess = () => {
    toast.success("Scan has been sent successfully.");
    history.push("/dashboard/current");
  };

  return (
    <>
      {dirUpSupport ? (
        <FolderUpload onSuccess={handleSuccess} />
      ) : (
        <FileUpload onSuccess={handleSuccess} />
      )}
    </>
  );
};

export default Uploader;
