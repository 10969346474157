import { Box } from "rebass";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import { useEffect, useRef, useState } from "react";

import { Loader } from "../../Common";
import { Pager } from "../../Layout";

type PdfViewerProps = {
  pdfFile: string;
};

const PdfViewer = ({ pdfFile }: PdfViewerProps) => {
  const [pageWidth, setPageWidth] = useState<number>(320);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const containerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPageWidth(containerEl.current?.clientWidth || 320);
  }, []);

  useEffect(() => {
    containerEl.current?.scrollIntoView({ behavior: "smooth" });
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  return (
    <Box ref={containerEl}>
      {!!numPages && (
        <Pager
          numPages={numPages}
          pageNumber={pageNumber}
          onPageChange={(page) => setPageNumber(page)}
        />
      )}

      <Document
        file={pdfFile}
        loading={<Loader caption="Loading report..." />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          width={pageWidth}
          loading={<Loader caption="Loading page..." />}
        />
      </Document>

      {!!numPages && (
        <Pager
          numPages={numPages}
          pageNumber={pageNumber}
          onPageChange={(page) => setPageNumber(page)}
        />
      )}
    </Box>
  );
};

export { PdfViewer };
