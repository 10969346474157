import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Flex } from "rebass";
import { Link } from "../Common";

const DashboardNav = () => {
  const match = useRouteMatch();

  return (
    <Flex mb={4} flexDirection={["column", "row"]}>
      <Link
        href="/dashboard/current"
        variant="tab"
        active={match.path.startsWith("/dashboard/current")}
      >
        Last scan details
      </Link>
      <Link
        href="/dashboard/archive"
        variant="tab"
        active={
          match.path.startsWith("/dashboard/archive") ||
          match.path.startsWith("/dashboard/scan")
        }
      >
        List of uploaded scans
      </Link>
      <Link
        href="/dashboard/start"
        variant="tab"
        active={match.path.startsWith("/dashboard/start")}
      >
        Upload new scan
      </Link>
    </Flex>
  );
};

export { DashboardNav };
