import { Text, Heading } from "rebass";
import { HtmlHead, Link } from "../components/Common";
import { Layout } from "../components/Layout/Layout";
import { Paragraph } from "../components/Layout/Paragraph";

function NotFound() {
  return (
    <>
      <HtmlHead title="Page not found" />
      <Layout>
        <Paragraph>
          <Heading variant="large" as="h1">
            404 - Page Not Found
          </Heading>
          <Text>
            Go back to the <Link href="/">homepage</Link>.
          </Text>
        </Paragraph>
      </Layout>
    </>
  );
}

export default NotFound;
