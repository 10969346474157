import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Text } from "rebass";
import { useDispatch, useSelector } from "react-redux";

import { HtmlHead, Link, Loader } from "../../components/Common";
import { Layout } from "../../components/Layout";
import { AuthContext } from "../../App";
import { ScanItemDetails } from "../../components/Dashboard/Scan";
import { queryScan } from "../../store/slices/datasets";
import { RootState } from "../../store";

function Scan() {
  const dispatch = useDispatch();
  const { path } = useParams<{ path: string }>();

  const { userId } = useContext(AuthContext);

  const queryStatus = useSelector(
    (state: RootState) => state.datasets.queryStatus
  );
  const scan = useSelector((state: RootState) => state.datasets.scan);

  const isLoading = queryStatus === "pending" ? true : false;

  useEffect(() => {
    dispatch(queryScan({ userId, uploadTimestamp: path }));
  }, []);

  return (
    <>
      <HtmlHead title="Scan details" />
      <Layout>
        {isLoading && scan?.uploadTimestamp !== path && <Loader />}

        {!scan && !isLoading && (
          <Text textAlign="center" fontSize="3">
            Corrupted scan data.{" "}
            <Link href="/dashboard/archive" fontSize="3">
              Go back.
            </Link>
          </Text>
        )}

        {scan && scan?.uploadTimestamp === path && (
          <ScanItemDetails scan={scan} />
        )}
      </Layout>
    </>
  );
}

export default Scan;
