import { Auth } from "aws-amplify";
import { Box, Button, Text, Flex, Heading } from "rebass";
import { Label, Input } from "@rebass/forms";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { AuthState, changeState } from "../../store/slices/auth";
import { signInSchema } from "./schema";
import { useState } from "react";
import { HtmlHead, Loader } from "../Common";

type FormType = {
  username: string;
  password: string;
};

function SignIn() {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(signInSchema),
  });

  const [loading, setLoading] = useState<boolean>(false);

  const signIn = async ({ username, password }: FormType) => {
    setLoading(true);
    toast.dismiss();

    try {
      await Auth.signIn(username, password);
    } catch (error) {
      toast.error(error.message, { autoClose: 10000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HtmlHead title="Sign In" />

      <Box
        py={2}
        px={2}
        mx="auto"
        width={["100%", "75%", "75%", "50%"]}
        sx={{ position: "relative" }}
        as="form"
        onSubmit={handleSubmit(signIn)}
      >
        {loading && <Loader overlay={true} />}
        <Heading variant="large" as="h1">
          Sign in to your account
        </Heading>
        <Box pb={2}>
          <Label htmlFor="username">Username</Label>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="user@example.com"
            ref={register()}
            variant={errors.username ? "error" : "input"}
          />
          <Text variant="formError">{errors.username?.message}</Text>
        </Box>
        <Box pb={4}>
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="your password"
            autoComplete="on"
            ref={register()}
            variant={errors.password ? "error" : "input"}
          />
          <Text variant="formError">{errors.password?.message}</Text>
        </Box>

        <Text pb={2}>
          Forgot your password?{" "}
          <Button
            variant="link"
            type="button"
            onClick={() =>
              dispatch(changeState({ authState: AuthState.ForgotPassword }))
            }
          >
            Reset Password
          </Button>
        </Text>

        <Flex alignItems="center" pb={2}>
          <Text width={1 / 2}>
            No Account?{" "}
            <Button
              variant="link"
              type="button"
              onClick={() =>
                dispatch(changeState({ authState: AuthState.SigningUp }))
              }
            >
              Create account
            </Button>
          </Text>

          <Box width={1 / 2} sx={{ textAlign: "right" }}>
            <Button type="submit" disabled={loading}>
              Submit
            </Button>
          </Box>
        </Flex>
      </Box>
    </>
  );
}

export { SignIn };
