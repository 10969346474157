import { Flex, Image, Text } from "rebass";

type LoaderProps = {
  caption?: string;
  overlay?: boolean;
};

const Loader = ({ caption, overlay }: LoaderProps) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      height="100%"
      backgroundColor="white"
      opacity={0.7}
      sx={{
        position: overlay ? "absolute" : "relative",
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <Image src="/spinner.svg" alt="loading" />
      {caption && <Text py={2}>{caption}</Text>}
    </Flex>
  );
};

export { Loader };
